import { ref } from "@vue/reactivity";
import { loginService } from "../../services/auth/authServices";
import useSesionToken from "./useSesionToken";
import store from "../../store/index";
import router from "../../router/index";
export default () => {

  const { setSesionToken } = useSesionToken();

  const loading = ref(false);

  const login = async (email, password) => {
    loading.value = true;

    const response = await loginService(email, password);

    if (response.status === 200) {
      const { token: access_token, ...user } = response.data;
      await initSesion(access_token, user);
    } else {
      loading.value = false;
    }

    return response;
  };

  const initSesion = async (sesionToken, user) => {
    setSesionToken(sesionToken);
    store.dispatch("User/setUser", user);
    router.push("/perfil");
  };

  return { loading, login, initSesion };
};
